<template>
  <div class="wrapper" @click="onClick">
    <el-form
      label-position="right"
      ref="form"
      :rules="formRules"
      :model="form"
      label-width="110px"
      style="margin-bottom: 8px"
    >
      <ContentBlock title="员工基本信息">
        <el-row :gutter="20">
          <el-col :span="12" style="height: 51px">
            <el-form-item label="姓名：" prop="name">
              <el-input
                placeholder="请输入姓名"
                v-model.trim="form.name"
                maxlength="30"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="手机号：" prop="phoneNumber">
              <span slot="label">
                手机号：
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="温馨提示：修改手机号后，登录账号会同时进行修改，如有微信绑定则需要重新进行绑定"
                  placement="top-start"
                >
                  <i class="el-icon-warning" />
                </el-tooltip>
              </span>
              <el-input
                placeholder="请输入手机号"
                v-model.trim="form.phoneNumber"
                maxlength="11"
                show-word-limit
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="邮箱地址：" prop="email">
              <el-input
                placeholder="请输入邮箱地址"
                v-model.trim="form.email"
                clearable
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="工号：" prop="jobNumber">
              <el-input
                placeholder="请输入员工工号"
                v-model.trim="form.jobNumber"
                clearable
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="height: 51px">
            <el-form-item label="角色权限：" prop="roleIdList" class="roleItem">
              <el-radio-group v-model="form.adminFlag" @change="handleRoleChange" style="margin-right: 10px">
                <el-radio :label="true">超管
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="超级管理员：拥有本公司所有权限"
                    placement="top-start"
                  >
                    <i class="el-icon-question" />
                  </el-tooltip>
                </el-radio>
                <el-radio :label="false">非超管</el-radio>
              </el-radio-group>
              <el-select v-model="form.roleIdList" placeholder="请选择角色权限" multiple :disabled="form.adminFlag">
                <el-option v-for="item in roleLists" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="直属部门：" prop="departmentId">
              <DepartMentSelector
                :isShow="true"
                v-model.trim="form.departmentId"
                :isAllowLeaf="true"
                placeholder="请选择直属部门"
                :name.sync="form.departmentName"
                @change="handleParentChanged"
              ></DepartMentSelector>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 51px">
            <el-form-item label="员工状态：" prop="status">
              <el-select v-model="form.status" :disabled="!id" placeholder="请选择员工状态">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </ContentBlock>
      <ContentBlock style="margin-top: 0" title="员工账号" v-if="!id">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号名称：" prop="phoneNumber">
              <el-input placeholder="请输入账号名称" v-model.trim="form.phoneNumber" disabled clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号状态：" prop="accountStatus">
              <el-radio-group v-model="form.accountStatus" disabled>
                <el-radio label="DISABLE">禁用</el-radio>
                <el-radio label="ACTIVE">激活</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </ContentBlock>
    </el-form>
    <bottom-panel>
      <template>
        <el-button style="margin-left: 1em" type="danger" @click="back">返回</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </template>
    </bottom-panel>
  </div>
</template>
<script>
import SystemSetting from '@/api/systemSetting';
import DepartMentSelector from '@/components/departMentSelector';

export default {
  name: 'EDIT_EMPLOYEE',
  props: {
    id: {
      type: String,
    },
  },
  components: {
    DepartMentSelector,
  },
  computed: {
    formRules () {
      return {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^1\d{10}$/,
            message: '手机号号码格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        departmentId: [
          {
            required: true,
            message: '请选择直属部门',
            trigger: ['blur', 'change'],
          },
        ],
        status: [
          {
            required: true,
            message: '请选择员工状态',
            trigger: ['blur', 'change'],
          },
        ],
        roleIdList: [
          {
            required: !this.form.adminFlag,
            message: '请选择角色权限',
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            type: 'email',
            message: '邮箱格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
      };
    },
  },
  data () {
    return {
      businessTags: [],
      loading: false,
      visible: false,
      form: {
        adminFlag: false,
        departmentId: '',
        email: '',
        labelList: [],
        name: '',
        phoneNumber: '',
        jobNumber: '',
        status: 'ON_JOB',
        roleIdList: [],
        departmentName: '',
        accountStatus: 'ACTIVE',
        accountStatusName: '激活',
        id: '',
      },
      roleLists: [],
      statusOptions: [
        {
          label: '在职',
          value: 'ON_JOB',
        },
        {
          label: '离职',
          value: 'LEAVE_OFFICE',
        },
      ],
      disabledIds: [],
      from: '',
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.from = from.fullPath;
    });
  },
  methods: {
    onClick (e) {
      const index = e.target.getAttribute('data-index');

      this.visible = !!index;
    },
    back (refresh = false) {
      this.$tabs.close({
        to: this.from,
        refresh,
      });
    },
    getRoleLists () {
      SystemSetting.getRoleLists({
        name: '',
        phoneNumber: '',
        pageFlag: false,
        orderByProperty: {
          orderByProperty: 'createTime',
          orderByType: 'DESC',
        },
      }).then(res => {
        this.roleLists = res.body?.list || [];
      });
    },

    getDetail () {
      SystemSetting.getDetailEmploy({
        id: this.id,
        findDepartmentFlag: true,
        findRoleFlag: true,
      }).then(res => {
        const roleIdList = (res.body?.roleRespDTOList || []).map(v => v.id);

        this.form = {
          ...res.body,
          roleIdList,
        };
      });
    },
    handleRoleChange (val) {
      if (val) {
        this.form.roleIdList = [];
      }
    },
    handleParentChanged (item) {
      this.form.departmentName = item.name;
      this.form.departmentId = item.id;
    },
    getbusinessTags () {
      SystemSetting.getBusScenario({ id: '945697336431206410' }).then(res => {
        res.body = res.body || [];

        this.businessTags = res.body.map(v => ({
          businessScenario: v.code,
          count: v.code === 'MAINTENANCE_SCENARIO' ? 0 : null,
          enable: v.code === 'MAINTENANCE_SCENARIO',
        }));
      });
    },

    submit () {
      const apiKey = this.id ? 'updateEmployee' : 'addEmployee';
      SystemSetting[apiKey]({
        ...this.form,
      }).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success',
        });

        this.back(true);
      });
    },
    save () {
      this.$refs.form.validate(async valid => {
        if (valid && this.form.adminFlag) {
          // 查询【在职、状态为启用的超级管理员】
          const { body: { list }} = await SystemSetting.getEmployLists({
            findRoleFlag: true,
            pageFlag: false,
            status: 'ON_JOB',
            accountStatus: 'ACTIVE',
            adminFlag: 1,
            orderBy: [
              {
                orderByProperty: 'lastUpdateTime',
                orderByType: 'DESC',
              },
            ],
          });
          const findCurrent = (list || []).findIndex(item => item.id === this.id);
          const onJobList = (list || []).map(item => `【${item.name}_在职】`) || [];
          const adminListStr = onJobList.join('，');
          if (onJobList.length === 1 && findCurrent !== -1) {
            this.submit();
          } else {
            this.$confirm(`当前超级管理员为${adminListStr}。是否要将【${this.form.name}】设置为超级管理员。设置多个超级管理员易造成权限滥用风险，请确认是否继续设置。`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }).then(() => {
              this.submit();
            }).catch(() => {});
          }
        } else if (valid && !this.form.adminFlag) {
          this.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created () {
    this.getRoleLists();
    this.getbusinessTags();

    if (this.id) {
      // 编辑
      this.getDetail();
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: 100%;

  /deep/ .roleItem .el-form-item__content {
    display: flex;
    .el-radio-group {
      .el-radio {
        margin-top: 10px;
      }
    }
  }

  /deep/ .roleItem .el-form-item__content .el-select {
    flex: 1;
  }

  /deep/ .roleItem {
    .el-form-item__error {
      left: 160px;
    }
  }
}
</style>
